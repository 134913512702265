import { emailMailToSubject } from "./config";

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

export function formatDate(dateParam) {
  const months = [
    "January", 
    "February", 
    "March", 
    "April", 
    "May", 
    "June", 
    "July", 
    "August", 
    "September", 
    "October", 
    "November", 
    "December"
  ];
  let arr = dateParam.split(/[- :]/);
  let day = arr[2].toString().charAt(0) === "0" ? arr[2].toString().charAt(1) : arr[2].toString();
  return months[parseInt(arr[1]) - 1] + " " + day + ", " + arr[0].toString();
}

export function emailMailToString(emailAddress) {
  return "mailto:" + emailAddress + "?subject=" + emailMailToSubject;
}

export function formatReturnTypeDisplay(string) {
  const stringEnd = string.indexOf(' ') === -1 ? string.length : string.indexOf(' ');
  return string.charAt(0).toUpperCase() + string.substring(1, stringEnd);
}