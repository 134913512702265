import React from 'react';

import { Button } from '../../atoms/Button';
import { FormField } from '../../atoms/FormField';
import { Loader } from '../../atoms/Loader';

import { commonMessages } from '../../../config';
import * as S from './styles';

export default class ProcessForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkFormInput = this.checkFormInput.bind(this);
    }

    checkFormInput(formList) {
        for (let i = 0; i < formList.length; i++) {
            if (formList[i].length === 0) {
                return true;
            }
        }
    }

    handleSubmit() {
        const processFormData = {
            code: this.state.code
        }
        this.props.handleSubmit(processFormData);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked :
            target.type === 'select-multiple' ? Array.from(target.selectedOptions, option => option.value)
                : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        const error = this.props.error
        const loading = this.props.loading;
        return (
            <S.ProcessFormContainer>
                <S.Header>{commonMessages.processForm.header}</S.Header>
                <S.Information>{commonMessages.processForm.information}</S.Information>
                <S.Error>{error}</S.Error>
                <FormField
                    nonConsumer
                    placeholder={commonMessages.processForm.code}
                    onChange={this.onChange}
                    name={"code"}
                    value={this.state.code}
                    disabled={loading}
                />
                <S.SubmitContainer>
                    <S.SubmitButtonContainer>
                        <Button
                            nonConsumer
                            label={commonMessages.processForm.submit}
                            handleSubmit={this.handleSubmit}
                            disabled={this.checkFormInput([
                                this.state.code
                            ]) || loading}
                        />
                    </S.SubmitButtonContainer>
                    {
                        loading &&
                        <S.LoaderContainer>
                            <Loader />
                        </S.LoaderContainer>
                    }
                </S.SubmitContainer>
            </S.ProcessFormContainer>
        );
    }
}