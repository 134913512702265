import styled from 'styled-components';

export const Loader = styled.div`
    border: 2.5px solid ${props => props.theme.colors.loaderBorder};
    border-top: 2.5px solid ${props => props.theme.colors.loaderSpinner};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 0.75s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
`;