import styled from 'styled-components';

export const InputPrimary = styled.input`
    font-weight: 600;
    margin: 0 auto;
    border: none;
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    height: 30px;
    width: 77%;
    padding-left: 10px;
    background: ${props => props.disabled ? props.theme.colors.formDisabled : 
        props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.formOutline};
    ::placeholder {
        color: ${props => props.theme.colors.placeholderText};
    }
    &:focus{
        outline: 0;
    }
`;

export const InputNonConsumer = styled(InputPrimary)`
    border-radius: ${props => props.theme.components.borderRadiusNonConsumer};
`;