import { createReducer } from '../../createReducer';

import * as s from './saveReducer';
import * as f from './fetchReducer';
import * as fa from './fetchAllReducer';
import * as p from './processReducer';

const initialStateSave = {...s.saveReturnInitial};

export const orderReturnSave = createReducer (initialStateSave, {
    SAVE_RETURN_REQUEST: s.saveReturnRequest,
    SAVE_RETURN_SUCCESS: s.saveReturnSuccess,
    SAVE_RETURN_FAILURE: s.saveReturnFailure
});

const initialStateFetch = {...f.fetchReturnInitial};

export const orderReturnFetch = createReducer (initialStateFetch, {
    FETCH_RETURN_REQUEST: f.fetchReturnRequest,
    FETCH_RETURN_SUCCESS: f.fetchReturnSuccess,
    FETCH_RETURN_FAILURE: f.fetchReturnFailure
});

const initialStateFetchAll = {...fa.fetchReturnsInitial};

export const orderReturnsFetch = createReducer (initialStateFetchAll, {
    FETCH_RETURNS_REQUEST: fa.fetchReturnsRequest,
    FETCH_RETURNS_SUCCESS: fa.fetchReturnsSuccess,
    FETCH_RETURNS_FAILURE: fa.fetchReturnsFailure
});

const initialStateProcess = {...p.processReturnInitial};

export const orderReturnProcess = createReducer (initialStateProcess, {
    PROCESS_RETURN_REQUEST: p.processReturnRequest,
    PROCESS_RETURN_SUCCESS: p.processReturnSuccess,
    PROCESS_RETURN_FAILURE: p.processReturnFailure
});