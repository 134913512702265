import styled from 'styled-components';

export const DivHeader = styled.div`
    margin: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${props => props.theme.colors.border};
`;

export const DivMain = styled.div`
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: hr-retailer, avenir-next, Helvetica, Arial, sans-serif;
`;