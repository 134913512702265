import styled from 'styled-components';

export const OrderFormContainer = styled.div`
    height: 510px;
    width: 375px;
    background: ${props => props.theme.colors.containerBackground};
    margin: auto;
    margin-top: 30px;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        background: ${props => props.theme.colors.white};
        width: auto;
        border: none;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 140px;
    justify-content: space-between;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
       height: 150px;
       margin-bottom: 10px;
    }
`;

export const Header = styled.h1`
    font-size: ${props => props.theme.typography.headerFontSize};
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.colors.primaryText};
`;

export const Information = styled.p`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
    a{
        color: ${props => props.theme.colors.informationText};
        &:hover {
            color: ${props => props.theme.colors.black};
        }
        &:focus {
            outline: 0;
            color: ${props => props.theme.colors.black};
        }
    }
`;

export const Error = styled.p`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 600;
    height: 10px;
    width: 80%;
    margin: 0 auto;
    color: ${props => props.theme.colors.errorText};
    line-height: ${props => props.theme.typography.lineHeight};
`;

export const SubmitContainer = styled.div`
    width: 80.5%;
    height: 34px;
    display: flex;
    margin: 0 auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 81%;
    }
`;

export const SubmitButtonContainer = styled.div`
    width: 100%;
`;

export const LoaderContainer = styled.div`
    height: 57%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -50px;
`;
