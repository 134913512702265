import styled from 'styled-components';


export const Line = styled.div`
    height: 55px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.border};
    margin-bottom: 13px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
`;

export const ExchangeLine = styled(Line)`
    height: 90px;
    flex-wrap: wrap;
`;

export const ExchangeContainer = styled.div`
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
`;

export const FormData = styled.div`
    width: 81px;
    height: 25px;
`;

export const ExchangeFormData = styled(FormData)`
    margin-top: 0px;
`;

export const Data = styled.div`
    color: ${props => props.invalid ? props.theme.colors.errorText : 
        props.theme.colors.informationData};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    width: 82px;
`;

export const ExchangeData = styled(Data)`
    margin: 0;
    height: 25px;
`;

export const InfoLineContainer = styled.div`
    width: 82px;
`;

export const Label = styled.h4`
    color: ${props => props.theme.colors.informationText};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 500;
    margin: auto 0px auto 0px;
    width: 85px;
`;

export const ReturnReasonData = styled.div`
    display: none;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    width: 290px;
    align-content: center;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
`;

export const ReturnReasonLabelContainer = styled.div`
    width: 290px;
    display: flex;
    justify-content:center;
    border: 1px solid ${props => props.theme.colors.border};
`;

export const BottomContainer = styled.div`
    width: 100%;
    height: 20px;
    margin-top: -6px;
    display: flex;
    line-height: 19px;
`;

export const ReturnReason = styled.div`
    width: 290px;
    height: 100%;
    display: flex;
    &:hover {
        cursor: default;
    }
    &:hover ${ReturnReasonData}{
        display: flex;
        justify-content: center;
    }
    &:hover ${ReturnReasonLabelContainer}{
        display: none;
    }

`;

export const ReturnReasonLabel = styled.h4`
    color: ${props => props.theme.colors.informationText};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    margin: auto 0px auto 0px;
    width: 85px;
    line-height: 5px;
`;