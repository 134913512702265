import React from 'react';
import { connect } from 'react-redux';

import { ProcessConfirmation } from '../ProcessConfirmation';
import { ProcessType } from '../../molecules/ProcessType';
import { Button } from '../../atoms/Button';
import { InformationLine } from '../../atoms/InformationLine';
import { InformationLink } from '../../atoms/InformationLink';
import { Loader } from '../../atoms/Loader';

import { commonMessages, commonTypes } from '../../../config';
import { emailMailToString } from '../../../util';

import { processReturn } from '../../../redux/return/actions';

import * as S from './styles';

class ProcessTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processLines: [],
            invalidLines: [],
            loading: false,
            submitted: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLineChange = this.handleLineChange.bind(this);
        this.redirectProcessForm = this.redirectProcessForm.bind(this);
    }

    handleLineChange(invalid, line) {
        var tempLineList = this.state.processLines;
        var tempLineListInvalid = this.state.invalidLines;
        if (invalid) {
            if (!tempLineListInvalid.includes(line)) {
                tempLineListInvalid.push(line);
            }
            for (let i = 0; i < tempLineList.length; i++) {
                if (tempLineList[i].id === line) {
                    tempLineList.splice(i, 1);
                }
            }
            this.setState({
                processLines: tempLineList,
                invalidLines: tempLineListInvalid
            });
        }
        else {
            for (let i = 0; i < tempLineListInvalid.length; i++) {
                if (tempLineListInvalid[i] === line.id) {
                    tempLineListInvalid.splice(i, 1);
                }
            }
            for (let i = 0; i < tempLineList.length; i++) {
                if (tempLineList[i].id === line.id) {
                    tempLineList.splice(i, 1);
                }
            }
            if (!(line.quantity_accepted + line.quantity_rejected === 0)) {
                tempLineList.push(line);
            }
            this.setState({
                processLines: tempLineList
            });
        }
    }

    handleSubmit() {
        const { dispatch } = this.props;
        this.setState({ loading: true });
        Promise.all([
            dispatch(processReturn({
                orderNumber: this.props.returnData.order_number,
                process_lines: this.state.processLines
            })),
        ]).then(() => {
            this.setState({
                loading: false,
                submitted: true
            });
        })
    }

    redirectProcessForm() {
        this.props.handleFormRedirect();
    }

    render() {
        const comments = this.props.returnData.comment_list;
        const commentList = [];
        if (!(comments === undefined)){
            for(let i = 0; i < comments.length; i++) {
                commentList.push(
                    <S.Comment key={i}>
                        {comments[i]}
                    </S.Comment>
                )
            }
        }

        const isSubmitted = this.state.submitted;

        if (isSubmitted) {
            return (
                <ProcessConfirmation
                    handleSubmit={this.redirectProcessForm}
                />
            );
        }

        const loading = this.state.loading;
        const lines = this.props.returnData.lines;
        const returnLineList = [], exchangeLineList = [];
        for (let i = 0; i < lines.length; i++) {
            switch (lines[i].return_type) {
                case commonTypes.return.backendReturnType:
                    returnLineList.push(lines[i]);
                    break;
                case commonTypes.exchange.backendReturnType:
                    exchangeLineList.push(lines[i]);
                    break;
                default:
            }
        }


        return (
            <S.ProcessTableContainer>
                <S.ContainerT>
                    <S.ContainerTL>
                        <S.ProcessContainer>
                            <S.LineContainer>
                                <ProcessType
                                    return
                                    lines={returnLineList}
                                    typeData={commonTypes.return}
                                    handleChange={this.handleLineChange}
                                />
                            </S.LineContainer>
                            <S.LineContainer>
                                <ProcessType
                                    exchange
                                    lines={exchangeLineList}
                                    typeData={commonTypes.exchange}
                                    handleChange={this.handleLineChange}
                                />
                            </S.LineContainer>
                        </S.ProcessContainer>
                    </S.ContainerTL>
                    <S.ContainerTR>
                        <S.UserInfoContainer>
                            <S.UserInfo>
                                <S.Header>{commonMessages.processTable.orderNumber} {this.props.returnData.order_number}</S.Header>
                                <InformationLine
                                    label={commonMessages.processTable.fulfillmentStatus}
                                    data={this.props.returnData.fulfillment_elapse}
                                />
                                <S.SubHeader>{commonMessages.processTable.consumerInformation}</S.SubHeader>
                                <S.ContactContainer>
                                    <InformationLine
                                        label={commonMessages.processTable.name}
                                        data={this.props.returnData.user_info.name}
                                    />
                                    <InformationLink
                                        href={emailMailToString(this.props.returnData.user_info.email)}
                                        label={commonMessages.processTable.email}
                                        data={this.props.returnData.user_info.email}
                                    />
                                    <InformationLine
                                        label={commonMessages.processTable.phone}
                                        data={this.props.returnData.user_info.phone}
                                    />
                                </S.ContactContainer>
                                <S.SubHeader>{commonMessages.processTable.address}</S.SubHeader>
                                <S.InfoContainer>
                                    <S.Information>
                                        {this.props.returnData.user_info.address}
                                    </S.Information>
                                    <S.Information>
                                        {this.props.returnData.user_info.city}
                                    </S.Information>
                                    <S.Information>
                                        {this.props.returnData.user_info.country_area}
                                    </S.Information>
                                    <S.Information>
                                        {this.props.returnData.user_info.zipcode}
                                    </S.Information>
                                    <S.Information>
                                        {this.props.returnData.user_info.country}
                                    </S.Information>
                                </S.InfoContainer>
                            </S.UserInfo>
                        </S.UserInfoContainer>

                        <S.AdditonalCommentsContainer>
                            <S.AdditionalComments>
                                <S.Header>{commonMessages.processTable.additionalComments}</S.Header>
                                {commentList}
                            </S.AdditionalComments>
                        </S.AdditonalCommentsContainer>

                        <S.SubmitContainer>
                            <S.ProcessButtonContainer>
                                <Button
                                    nonConsumer
                                    label={loading ? <S.LoaderContainer>
                                        <Loader />
                                    </S.LoaderContainer> :
                                        commonMessages.processTable.process}
                                    handleSubmit={this.handleSubmit}
                                    disabled={
                                        this.state.processLines.length === 0 ||
                                        this.state.invalidLines.length > 0 ||
                                        loading
                                    }
                                />
                            </S.ProcessButtonContainer>
                        </S.SubmitContainer>
                    </S.ContainerTR>
                </S.ContainerT>
            </S.ProcessTableContainer>
        );
    }
}

function mapStateToProps(state) {
    const { orderReturnFetch } = state;
    const { orderReturnProcess } = state;
    var returnData = undefined;
    if (orderReturnFetch) {
        const { response } = orderReturnFetch;
        if(response) {
            returnData = response.return;
        }
    }
    return {
        returnData,
        orderReturnProcess
    }
}

const processTableContainer = connect(mapStateToProps)(ProcessTable);
export { processTableContainer as ProcessTable }