import styled from 'styled-components';

export const OrderTableContainer = styled.div`
    height: 600px;
    width: 700px;
    background: ${props => props.theme.colors.containerBackground};
    margin: auto;
    margin-top: 25px;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        background: ${props => props.theme.colors.white};
        border: none;
        margin-top: 50px;
        height: auto;
        width: auto;
        border: none;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const StyleContainer = styled.div`
    height: 55%;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        width: 8px;
    }
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollBarTrack};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollBarHandle};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        height: auto;
    }
`;

export const FooterContainer = styled.div`
    height: 155px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        flex-direction: column;
    }
`;

export const FooterLContainer = styled.div`
    height: 100%;
    width: 37%;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: auto;
    }
`;

export const FooterMContainer = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: auto;
        margin-top: 25px;
    }
`;

export const FooterRContainer = styled.div`
    height: 100%;
    width: 32%;
    position: relative;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: auto;
        margin-top: 25px;
        padding-bottom: 25px;
    }
`;

export const SubmitContainer = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 11px;
    margin-left: 24px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        position: relative;
        margin-bottom: 0px;
        margin-left: 0px;
    }
`;

export const LoaderContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -31px;
`;

export const Header = styled.h1`
    font-size: ${props => props.theme.typography.headerFontSize};
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.colors.primaryText};
`;

export const IHContainer = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 15px;
    display: flex;
`;

export const IHLContainer = styled.div`
    width: 50%;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: 100%;
    }
`;

export const IHRContainer = styled.div`
    width: 50%;
`;

export const IHOrderNumber = styled.div`

`;

export const IHDate = styled.div`
    
`;

export const IHDateP = styled.div`
    margin-bottom: 10px;
`;

export const IHDateD = styled.div`

`;

export const InformationHeader = styled.h2`
    font-size: ${props => props.theme.typography.infoHeaderFontSize};
    font-weight: 600;
    margin: 0 auto;
    color: ${props => props.theme.colors.black};
    line-height: ${props => props.theme.typography.lineHeight};
`;

export const InformationFooter = styled.h2`
    font-size: ${props => props.theme.typography.infoFooterFontSize};
    font-weight: 500;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
`;

export const Information = styled.p`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        margin-top: auto;
    }
`;

export const DownloadContainer = styled.div`
    height: 19px;
    width: 97px;
    margin-left: auto;
    margin-top: 3px;
`;

export const DownloadButton = styled.button`
    display: flex;
    padding: 0px;
    background: none;
    border: none;
    font-weight: 600;
    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.iconHover};
    }
    &:disabled {
        cursor: default;
        color: ${props => props.theme.colors.iconDisabled};
    }
    svg{
        margin-top: 1px;
        margin-left: 5px;
        &:hover {
            cursor: pointer;
            fill: ${props => props.theme.colors.iconHover};
        }
    }

`;