import * as S from './styles';

export default function Button(props) {
    function handlePress(){
        if(props.label) {
            props.handleSubmit(props.name, props.label);
        }
        else{
            props.handleSubmit();
        }
        
    }

    const Button = !props.secondary ? props.nonConsumer ? 
        S.NonConsumerPrimary : S.Primary : S.Secondary;

    return (
        <Button
            onClick={handlePress}
            onKeyPress={handlePress}
            disabled={props.disabled}
        >
            {props.label}
        </Button>
            
    );
}