import styled from 'styled-components';

export const TextArea = styled.textarea`
    resize: none;
    height: 77px;
    width: 185px;
    padding: 10px;
    margin-right: 27px;
    margin-left: -2px;
    margin-top: -8px;
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    border: 1px solid ${props => props.theme.colors.border};
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollBarTrack};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollBarHandle};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &:focus{
        outline: 0;
    }
    font-family: hr-retailer, avenir-next, Helvetica, Arial, sans-serif;
    font: hr-retailer, avenir-next, Helvetica, Arial, sans-serif;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: 50vw;
    }
`;