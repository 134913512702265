import axios from 'axios';

import { processCredentialInputId } from "../config";

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export function post(endpoint, data) {
    var params = window.location.href.split('?');
    if(params.length > 1){
        data[processCredentialInputId] = params[1].split('=')[1];
    }
    return axios
        .post(endpoint, data)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
}