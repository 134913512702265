import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from './app';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './config';
import { store } from './redux/store';

import * as S from './styles';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <S.DivMain>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </S.DivMain>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);