export const fetchReturnsInitial = {
    isSaving: false,
    items: [],
    error: null,
    count: 0
};

export function fetchReturnsRequest(state) {
    return Object.assign({}, state, {
        isSaving: true,
        error: null,
    });
}

export function fetchReturnsSuccess(state, action) {
    return Object.assign({}, state, {
        isSaving: false,
        data: action.data,
        response: action.response.data,
        error: null,
    });
}

export function fetchReturnsFailure(state, action) {
    return Object.assign({}, state, {
        isSaving: false,
        error: action.error.response.data.error
    });
}