import React from 'react';

import { Button } from '../../atoms/Button';

import { commonMessages } from '../../../config';

import * as S from './styles';

export default class ReturnConfirmation extends React.Component {
    render() {
        return (
            <S.ReturnConfirmationContainer>
                <S.Header>{commonMessages.returnConfirmation.header}</S.Header>
                <S.Information>{commonMessages.returnConfirmation.information}</S.Information>

                <S.SubmitContainer>
                    <Button
                        handleSubmit={() => this.props.link.click()}
                        label={commonMessages.returnConfirmation.returnFormLabel}
                    />
                </S.SubmitContainer>

            </S.ReturnConfirmationContainer>
        );
    }
}