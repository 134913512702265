function findSelected(attr_id, selectedVariantAttributes) {
  for (let i = 0; i < selectedVariantAttributes.length; i++) {
    if (selectedVariantAttributes[i].attribute_id.toString() === attr_id.toString()) {
      return selectedVariantAttributes[i];
    }
  }
  return null;
}

function hasQuantity(keyIn, variantMap) {
  var keyList = Object.getOwnPropertyNames(variantMap);
  for (let i = 0; i < keyList.length; i++) {
    var sku = keyList[i];
    if (sku.indexOf(keyIn) > -1) {
      if (variantMap[sku] > 0) return true;
    }
  }
  return false;
}

export function buildSelectedKey(
  product,
  selectedVariantAttributes,
  attributesMap
) {
  if (!product.metadata["skuKeyString"]) {
    alert("no skuKeyString configured on product type");
    return "";
  }
  var skuOrderString = product.metadata["skuKeyString"];
  var attributesKeyList = Object.getOwnPropertyNames(attributesMap);
  for (let i = 0; i < attributesKeyList.length; i++) {
    var attr = attributesKeyList[i];
    var attrType = attributesMap[attr];
    if (skuOrderString.indexOf(attrType) > -1) {
      if (findSelected(attr, selectedVariantAttributes)) {
        skuOrderString = skuOrderString.replace(
          "{" + attrType + "}",
          "{" + attrType + "}" + findSelected(attr, selectedVariantAttributes).attribute_value_name
        );
      }
    }
  }
  skuOrderString = skuOrderString.replace(
    "{Style}",
    "{Style}" + Object.keys(product.sku_quantity_map)[0].split("_")[0]
  );
  return skuOrderString;
}

function convertToActualKey(key, aMapList) {
  var actualKey = key;
  for (let i = 0; i < aMapList.length; i++) {
    actualKey = actualKey.replace("{" + aMapList[i] + "}", "");
  }
  actualKey = actualKey.replace("{Style}", "");
  return actualKey;
}

function findVals(missingAttr, product) {
  for (let i = 0; i < product.exchangeable_variant_attrs.length; i++) {
    if (product.exchangeable_variant_attrs[i].attribute.name === missingAttr) {
      return product.exchangeable_variant_attrs[i].attribute.values;
    }
  }
}


// make a key containing the currently being checked attribute
// and check the variant map with that
function hasQuantityBySelectedKey(
  currentAttribute,
  currentAttributeType,
  selectedKey,
  variantMap,
  selectedVariantAttributes,
  attributesMap,
  product
) {
  var selectedKeyAndCurrentAttr = selectedKey;

  // current attribute has value of same type already in key
  if (findSelected(currentAttribute.attribute_id, selectedVariantAttributes)) {
    // replace placeholder and value up to next {
    var pHoldIndex = selectedKey.indexOf("{" + currentAttributeType + "}");
    var replaceUpTo = selectedKey.length;
    for (var i = pHoldIndex; i < selectedKey.length; i++) {
      var c = selectedKey.charAt(i);

      if (i !== pHoldIndex && (c === '_' || c === '{')) {
        replaceUpTo = i;
        break;
      }
    }
    var firstHalf = selectedKey.substring(0, pHoldIndex);
    var secondHalf = selectedKey.substring(replaceUpTo);
    selectedKeyAndCurrentAttr =
      firstHalf + "{" + currentAttributeType + "}" + currentAttribute.attribute_value_name + secondHalf;
  } else {
    selectedKeyAndCurrentAttr = selectedKeyAndCurrentAttr.replace(
      "{" + currentAttributeType + "}",
      "{" + currentAttributeType + "}" + currentAttribute.attribute_value_name
    )
  }

  var missingAttrs = [];
  var aMapList = Object.keys(attributesMap);

  for (let i = 0; i < aMapList.length; i++) {
    var aId = attributesMap[aMapList[i]];
    if (!findSelected(aId, selectedVariantAttributes) && (aMapList[i] !== currentAttributeType))
      missingAttrs.push(aMapList[i]);
  }

  if (missingAttrs.length === 0) {
    var actualKey = convertToActualKey(selectedKeyAndCurrentAttr, aMapList);
    return variantMap[actualKey] > 0;
  }

  for (let i = 0; i < missingAttrs.length; i++) {
    // for each missing attribute start inserting attribute values 
    // if you find stock return true
    var missingAttr = missingAttrs[i];
    var aVals = findVals(missingAttr, product);
    for (const [_key2, value2] of Object.entries(aVals)) {
      for (const [key3, _value3] of Object.entries(variantMap)) {
        if ((key3.indexOf(value2.name || "") && (_key2 !== null && _value3 !== null)) > -1) {
          if (key3.indexOf(currentAttribute.attribute_value_name || "") > -1) {
            if (variantMap[key3] > 0) {
              return true;
            }
          }
        }
      }
    }

  }

  return false;
}

export function isOutOfStockVariant(
  product,
  selectedVariantAttributes,
  currentAttribute,
) {

  // map of attribute ids for attribute types
  var attributesMap = product.exchangeable_variant_attrs.reduce(function (map, obj) {
    var n = obj.attribute.id || "fakekey"
    map[n] = obj.attribute.name;
    return map;
  }, {});

  var attributesMapRev = product.exchangeable_variant_attrs.reduce(function (map, obj) {
    var n = obj.attribute.name || "fakekey"
    map[n] = obj.attribute.id;
    return map;
  }, {});

  var selectedKey = buildSelectedKey(
    product,
    selectedVariantAttributes,
    attributesMap
  );

  if (selectedVariantAttributes.length === 0) {
    return !hasQuantity(
      currentAttribute.attribute_value_name,
      product.sku_quantity_map
    );
  }

  return !hasQuantityBySelectedKey(
    currentAttribute,
    currentAttribute.attribute_name,
    selectedKey,
    product.sku_quantity_map,
    selectedVariantAttributes,
    attributesMapRev,
    product
  );
}

/*
data structure for parameters to isOutOfStockVariant

var selectedAttributesValues = [
            {
                attribute_id: 9,
                attribute_name: "Band",
                attribute_slug: 'webchest',
                attribute_value_slug: '32',
                attribute_value_name: '32',
            },
            {
                attribute_id: 10,
                attribute_name: "Cup",
                attribute_slug: 'webcup',
                attribute_value_slug: 'c',
                attribute_value_name: 'C',
            },
            {
                attribute_id: 12,
                attribute_name: "Color",
                attribute_slug: 'color',
                attribute_value_slug: 'black',
                attribute_value_name: 'Black',
            }
        ];
        var currentAttributeValue = {
            attribute_id: 9,
            attribute_name: "Band",
            attribute_slug: 'webchest',
            attribute_value_slug: '36',
            attribute_value_name: '36',
        };
 */