import styled from 'styled-components';

export const ProcessTableContainer = styled.div`
    height: 91vh;
    width: 95vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const Header = styled.h1`
    font-size: ${props => props.theme.typography.infoHeaderFontSize};
    font-weight: 600;
    color: ${props => props.theme.colors.black};
    line-height: ${props => props.theme.typography.lineHeight};
    margin: 0;
    margin-top: 0px;
    margin-bottom: 17px;
`;


export const SubHeader = styled.h4`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 500;
    width: 80%;
    margin: 0;
    text-align: left;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
    margin-top: 15px;
`;

export const Information = styled.h4`
    color: ${props => props.theme.colors.informationData};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    margin: 0;
    height: 20px;
    margin-right: 15px;
`;

export const Comment = styled.h4`
    color: ${props => props.theme.colors.informationData};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
`;

export const ContainerT = styled.div`
    padding: 0px;
    height: 100%;
    display: flex;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        flex-direction: column;
        height: auto;
    }
`;

export const ContainerB = styled.div`
    width: 99.9%;
`;

export const ContainerTL = styled.div`
    width: 75%;
    height: 100%;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 150%;
    }
`;

export const ContainerTR = styled.div`
    width: 25%;
    height: 100%;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 100%;
    }
`;

export const UserInfoContainer = styled.div`
    height: 60%;
    width: 100%;
`;

export const SubmitContainer = styled.div`
    height: 5%;
    width: 100%;
`;

export const AdditonalCommentsContainer = styled.div`
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const AdditionalComments = styled.div`
    width: 88%;
    height: 80%;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    margin-left: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.scrollBarTrack};
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.scrollBarHandle};
    }
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 92%;
    }
`; 

export const UserInfo = styled.div`
    width: 88%;
    height: 100%;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 15px;
    margin-left: auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 92%;
    }
`;

export const InfoContainer = styled.div`
    width: 180px;
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        width: 600px;
        flex-direction: row;
        height: auto;
    }
`;

export const ContactContainer = styled(InfoContainer)`
    flex-direction: column;
    height: 90px;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        flex-direction: row;
        height: auto;
    }
`;

export const ProcessContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const LineContainer = styled.div`
    width: 100%;
    height: 47%;
`;

export const ProcessButtonContainer = styled.div`
    width: 22%;
    position: absolute;
    bottom: 0;
    right: 0;
    // @media(max-width: ${props => props.theme.screen.breakpoint}) {
    //     position: relative;
    //     margin-left: 17px;
    //     margin-top: -50px;
    // }
`;

export const LoaderContainer = styled.div`
    width: 10%;
    margin: auto;
`;