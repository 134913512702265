import styled from 'styled-components';

export const TypeContainer = styled.div`
    border: 1px solid ${props => props.theme.colors.border};
    height: 100%;
    box-sizing:border-box; 
    padding: 15px;
`;

export const ReturnContainer = styled(TypeContainer)`

`;

export const ExchangeContainer = styled(TypeContainer)`
    
`; 

export const ProcessTypeContainer = styled.div`
    
`;

export const LineContainer = styled.div`
    height: 76%;
    width: 100%;
    margin-left: auto;
    margin-top: 10px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const LineContainerInd = styled.div`
    display: flex;
`;

export const Header = styled.h4`
    margin: 0;
    line-height: 1.5rem;
    font-size: 0.9rem;
`;

export const SubHeaderContainer = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.colors.border};
`;

export const SubHeader = styled.div`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 500;
    margin: 0;
    text-align: left;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
    width: 80px;
`;