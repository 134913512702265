import React from 'react';
import { connect } from 'react-redux';

import { OrderForm } from '../../components/organisms/OrderForm';
import { OrderTable } from '../../components/organisms/OrderTable';

import { fetchOrder } from '../../redux/order/actions';

class Order extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(baseFormData) {
        const { dispatch } = this.props;
        this.setState({ loading: true });
        Promise.all([
            dispatch(fetchOrder(baseFormData)),
        ]).then(() => {
            if(!this.props.errorProp){
                this.setState({ 
                    redirect: true,
                    loading: false
                });
            } else {
                this.setState({ 
                    loading: false
                });
            }
        });
    }

    render() {
        const loading = this.state.loading;
        const redirect = this.state.redirect;
        if (redirect) {
            return (
                <OrderTable />
            );
        }
        return (
            <OrderForm
                handleSubmit={this.handleSubmit}
                error={this.props.errorProp}
                loading={loading}
            />
        );
    }
}

function mapStateToProps(state) {
    const { order } = state;
    var errorProp = order.error;
    return {
        errorProp
    };
}

const orderContainer = connect(mapStateToProps)(Order);
export { orderContainer as Order };