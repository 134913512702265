import { useState } from 'react';

import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import * as S from './styles';

export default function Dropdown(props) {
    const [active, setActive] = useState(false);
    const [selected, setSelected] = useState(null);
    const label = props.label;
    const options = props.options;

    function handleChange(data) {
        setSelected(data);
        setActive(!active);
        props.onChange(props.name, data)
    }

    function buildOptionList(list) {
        const optionList = [];
        for(let i = 0; i < list.length; i++) {
            optionList.push(
                <S.OptionContainer key={list[i]}>
                    <S.OptionButton
                        onClick={() => handleChange(list[i][1])}
                    >
                        {list[i][0]}
                    </S.OptionButton>
                </S.OptionContainer>
            );
        }
        return optionList;
    }

    return(
        <S.DropdownContainer>
            <S.SelectContainer>
                <S.DropdownButton
                    disabled={props.disabled}
                    onClick={() => setActive(!active)}
                >
                    {selected !== null ? props.returnToLabel ? label : selected : label}
                    <S.IconContainer>
                        {active ? props.returnToLabel ? <FiChevronDown /> : <FiChevronUp /> : <FiChevronDown />}
                    </S.IconContainer>
                </S.DropdownButton>            
            </S.SelectContainer>
            {
                active && !props.returnToLabel &&
                <S.BodyContainer>
                    {buildOptionList(options)}
                </S.BodyContainer>
            }
        </S.DropdownContainer>
    );
}