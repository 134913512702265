import React from 'react';

import { VariantTile } from '../../atoms/VariantTile';
import { isOutOfStockVariant } from '../../organisms/OrderTable/checkStock';

import * as S from './styles';

export default class VariantSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attributesSelected: [],
            activeTiles: {}
        }

        this.sortByDisplayOrder = this.sortByDisplayOrder.bind(this);
        this.onHandleTileClick = this.onHandleTileClick.bind(this);
        this.setActiveTiles = this.setActiveTiles.bind(this);
        this.isTileDisabled = this.isTileDisabled.bind(this);
    }

    sortByDisplayOrder(arr) {
        var r = arr.sort(function (a, b) {
            var valueA = a.attribute.metadata["productPageDisplayOrder"];
            var valueB = b.attribute.metadata["productPageDisplayOrder"];
            if ((valueA.length > 0) && (valueB.length < 1)) {
                return 1;
            } else if ((valueB.length > 0) && (valueA.length < 1)) {
                return -1;
            } else if ((valueB.length === 0) && (valueA.length === 0)) {
                return 0;
            }
            if (valueA < valueB) {
                return -1;
            } else if (valueA > valueB) {
                return 1;
            } else {
                return 0;
            }
        });
        return r;
    }

    setActiveTiles(type, value) {
        var tempObj = this.state.activeTiles;
        for(let i = 0; i < this.props.variants.exchangeable_variant_attrs.length; i++) {
            if(type === this.props.variants.exchangeable_variant_attrs[i].attribute.slug){
                tempObj[type] = value;
            }
            else {
                tempObj[this.props.variants.exchangeable_variant_attrs[i].attribute.slug] = 
                    this.state.activeTiles[this.props.variants.exchangeable_variant_attrs[i].attribute.slug];
            }
        }
        this.setState({
            activeTiles: tempObj
        });
    }

    onHandleTileClick(attributeData, isDisabled) {
        if(isDisabled) return;

        this.setActiveTiles(
            attributeData.attribute_slug, 
            attributeData.attribute_value_slug
        )
        var tempList = this.state.attributesSelected;
        for(let i = 0; i < tempList.length; i++) {
            if(tempList[i].attribute_slug === attributeData.attribute_slug) {
                tempList.splice(i,1);
            }
        }
        tempList.push(attributeData);
        this.setState({
            attributesSelected: tempList
        });
        if(this.state.attributesSelected.length === this.props.variants.exchangeable_variant_attrs.length) {
            this.props.handleVariantList(this.state.attributesSelected);
        }
    }

    isTileDisabled(currentAttribute){
        var i =  isOutOfStockVariant(
            this.props.variants,
            this.state.attributesSelected,
            currentAttribute
        );
        return i;
    }

    render() {
        const orderedVariantList = this.sortByDisplayOrder(this.props.variants.exchangeable_variant_attrs);
        const variantComponentList = [];
        for(let i = 0; i < orderedVariantList.length; i++) {
            const tiles = [];
            for(let j = 0; j < orderedVariantList[i].attribute.values.length; j++) {
                tiles.push(
                    <VariantTile 
                        key={orderedVariantList[i].attribute.values[j].slug}
                        variantAttribute={orderedVariantList[i]}
                        variantData={orderedVariantList[i].attribute.values[j]}
                        onHandleClick={this.onHandleTileClick}
                        activeTiles={this.state.activeTiles}
                        isDisabled={(cAttr) => this.isTileDisabled(cAttr)}
                    />
                );
            }
            variantComponentList.push(
                <S.SectionContainer key={orderedVariantList[i].attribute.slug}>
                    <S.SectionHeader>
                        {orderedVariantList[i].attribute.name}
                    </S.SectionHeader>
                    <S.TilesContainer>
                        {tiles}
                    </S.TilesContainer>
                </S.SectionContainer>
            );
        }
        return (
            <S.VariantSelectorContainer>
                {variantComponentList}
            </S.VariantSelectorContainer>
        );
    }
}