import { createReducer } from '../../createReducer';

import * as r from './fetchReducer';

const initialState = {...r.fetchOrderInitial};

export const order = createReducer (initialState, {
    FETCH_ORDER_REQUEST: r.fetchOrderRequest,
    FETCH_ORDER_SUCCESS: r.fetchOrderSuccess,
    FETCH_ORDER_FAILURE: r.fetchOrderFailure
});

export const orderForm = createReducer (initialState, {
    FETCH_ORDER_FORM_REQUEST: r.fetchOrderFormRequest,
    FETCH_ORDER_FORM_SUCCESS: r.fetchOrderFormSuccess,
    FETCH_ORDER_FORM_FAILURE: r.fetchOrderFormFailure
});

