import * as S from './styles';

export default function InformationLink(props) {
    return (
        <S.ILContainer>
            <S.Label>
                {props.label}: 
            </S.Label>
            {props.data &&
                <S.DataContainer>
                    <S.Space>&nbsp;&nbsp;</S.Space>
                    <S.Data href={props.href}>
                        {props.data}
                    </S.Data>
                </S.DataContainer>
            }
        </S.ILContainer>
    );
}