import React from 'react';
import { MdClose } from 'react-icons/md';

import { Image } from '../../atoms/Image';
import { Error } from '../../atoms/Error';
import { Button } from '../../atoms/Button';
import { FormField } from '../../atoms/FormField';
import { InformationLine } from '../../atoms/InformationLine';
import { VariantSelector } from '../VariantSelector';

import { currencyFormat } from '../../../util';

import {
    returnReasons,
    returnTypes,
    commonMessages
} from '../../../config';
import * as S from './styles';

export default class ReturnModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variantList: [],
            reason: undefined,
            returnType: undefined,
            returnData: undefined,
            raiseError: false,
            quantity: this.props.styleData.quantity_fulfilled - this.props.styleData.quantity_returned,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.close = this.close.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validateQuantity = this.validateQuantity.bind(this);
        this.handleVariantList = this.handleVariantList.bind(this);
        this.validateVariantList = this.validateVariantList.bind(this);
        this.checkQuantityInput = this.checkQuantityInput.bind(this);
    }

    handleSubmit(name, data) {
        this.setState({
            [name]: data
        });
    }

    validateQuantity(quantity) {
        if (parseInt(quantity) > 0 && parseInt(quantity) <=
            this.props.styleData.quantity_fulfilled -
            this.props.styleData.quantity_returned) {
            this.setState({
                raiseError: false
            });
            return true;
        }
        else {
            this.setState({
                raiseError: true,
                error: "Invalid quantity"
            });
            return false;
        }
    }

    checkQuantityInput(input) {
        return input.length === 0;
    }

    handleVariantList(list) {
        this.setState({
            variantList: list
        });
    }

    validateVariantList(list, compareList) {
        return list.length !== compareList.length;
    }

    close(submission) {
        if (submission === true) {
            if (this.validateQuantity(this.state.quantity)) {

                var exSku = null;

                if (this.state.returnType === returnTypes.exchange.label) {
                    var skuKeyString = this.props.styleData.product.metadata.skuKeyString;
                    var productNumber = this.props.styleData.product_sku.split('_')[0];
                    exSku = skuKeyString.replace('{Style}', productNumber);

                    for (let i = 0; i < this.state.variantList.length; i++) {
                        exSku = exSku.replace(
                            '{' + this.state.variantList[i].attribute_name + '}',
                            this.state.variantList[i].attribute_value_name
                        );
                    }
                }


                const returnData = {
                    id: this.props.styleData.id,
                    styleName: this.props.styleData.product_name,
                    reason: this.state.reason,
                    returnType: this.state.returnType,
                    quantity: this.state.quantity,
                    amount: this.state.returnType === returnTypes.exchange.label ? 0 :
                        this.props.styleData.unit_price_gross_amount * this.state.quantity,
                    exchangeSku: exSku,
                    returnSku: this.props.styleData.product_sku,
                    returnVariantId: this.props.styleData.variant.id
                }
                this.props.onClose(returnData);
            }
        }
        else {
            this.props.onClose()
        }
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked :
            target.type === 'select-multiple' ? Array.from(target.selectedOptions, option => option.value)
                : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            raiseError: false
        });
    }

    render() {
        var activeList = [];
        var activeHeader = "";
        if (!this.state.reason) {
            const reasonList = []
            for (let i in returnReasons) {
                const reasons = [];
                for (let j = 0; j < returnReasons[i].data.length; j++) {
                    reasons.push(
                        <S.ButtonContainer key={returnReasons[i].data[j]}>
                            <Button
                                secondary
                                label={returnReasons[i].data[j]}
                                name={"reason"}
                                handleSubmit={this.handleSubmit}
                            />
                        </S.ButtonContainer>
                    );
                }
                reasonList.push(
                    <S.SectionContainer key={returnReasons[i].label}>
                        <S.SectionHeader>
                            {returnReasons[i].label}
                        </S.SectionHeader>
                        {reasons}
                    </S.SectionContainer>
                );
            }
            activeList = reasonList;
            activeHeader = commonMessages.orderTable.modalReasonHeader;
        }
        else if (this.state.reason && !this.state.returnType) {
            const returnTypeList = [];
            for (let i in returnTypes) {
                returnTypeList.push(
                    <S.SectionContainer key={returnTypes[i].label}>
                        <S.ButtonContainer>
                            <Button
                                secondary
                                label={returnTypes[i].label}
                                name={"returnType"}
                                handleSubmit={this.handleSubmit}
                            />
                        </S.ButtonContainer>
                    </S.SectionContainer>
                )
            }
            activeList = returnTypeList;
            activeHeader = commonMessages.orderTable.modalReturnTypeHeader;
        }
        else if (this.state.returnType) {
            if (this.state.returnType === returnTypes.exchange.label) {
                activeList = [
                    <S.SectionHeader key={"quantityAmount"}>
                        <InformationLine
                            label={commonMessages.orderTable.modalMaxQuantity}
                            data={this.props.styleData.quantity_fulfilled - this.props.styleData.quantity_returned}
                        />
                        <S.MessageContainer key={"messageContainer"}>
                            {this.state.raiseError && <Error message={this.state.error} />}
                        </S.MessageContainer>
                    </S.SectionHeader>,
                    <S.FormContainer key={"quantityForm"} >
                        <FormField
                            placeholder={commonMessages.orderTable.modalQuantity}
                            onChange={this.onChange}
                            name={"quantity"}
                            value={this.state.quantity}
                        />
                    </S.FormContainer>,
                    <S.VariantContainer key={"variantContainer"}>
                        <VariantSelector
                            variants={this.props.styleData.product}
                            handleVariantList={this.handleVariantList}
                        />
                    </S.VariantContainer>,
                    <S.ButtonContainer key={"exchangeSubmit"}>
                        <Button
                            label={returnTypes.exchange.label}
                            name={"submission"}
                            handleSubmit={() => this.close(true)}
                            disabled={this.validateVariantList(this.state.variantList,
                                this.props.styleData.product.exchangeable_variant_attrs) ||
                                this.checkQuantityInput(this.state.quantity)}
                        />
                    </S.ButtonContainer>
                ];
                activeHeader = commonMessages.orderTable.modalExchanging
                    + " " + this.props.styleData.product_name
                    + " " + this.props.styleData.size
                    + " " + this.props.styleData.color;
            }
            else {
                activeList = [
                    <S.SectionHeader key={"quantityAmount"}>
                        <InformationLine
                            label={commonMessages.orderTable.modalMaxQuantity}
                            data={this.props.styleData.quantity_fulfilled - this.props.styleData.quantity_returned}
                        />
                        <S.MessageContainer key={"messageContainer"}>
                            {this.state.raiseError && <Error message={this.state.error} />}
                        </S.MessageContainer>
                    </S.SectionHeader>,
                    <S.FormContainer key={"quantityForm"} >
                        <FormField
                            placeholder={commonMessages.orderTable.modalQuantity}
                            onChange={this.onChange}
                            name={"quantity"}
                            value={this.state.quantity}
                        />
                    </S.FormContainer>,
                    <S.ButtonContainer key={"refundSubmit"}>
                        <Button
                            label={returnTypes.refund.label}
                            name={"submission"}
                            handleSubmit={() => this.close(true)}
                            disabled={this.checkQuantityInput(this.state.quantity)}
                        />
                    </S.ButtonContainer>
                ];
                activeHeader = commonMessages.orderTable.modalReturning
                    + " " + this.props.styleData.product_name
                    + " " + this.props.styleData.size
                    + " " + this.props.styleData.color;

            }
        }


        return (
            <S.ReturnModal>
                <S.ContainerT>
                    <S.CloseContainer>
                        <S.CloseButton
                            onClick={() => this.close(false)}
                        >
                            <MdClose />
                        </S.CloseButton>
                    </S.CloseContainer>
                </S.ContainerT>

                <S.ContainerB>
                    <S.ContainerL>
                        <S.ImageContainer>
                            <Image src={this.props.styleData.img} />
                        </S.ImageContainer>
                        <S.ProductInfo>
                            <InformationLine 
                                label={commonMessages.style.name}
                                data={this.props.styleData.product_name}
                            />
                            <InformationLine 
                                label={commonMessages.style.size}
                                data={this.props.styleData.size}
                            />
                            <InformationLine 
                                label={commonMessages.style.color}
                                data={this.props.styleData.color}
                            />
                            <InformationLine 
                                label={commonMessages.style.price}
                                data={currencyFormat.format(this.props.styleData.unit_price_gross_amount)}
                            />
                        </S.ProductInfo>
                    </S.ContainerL>

                    <S.ContainerR>
                        <S.RHContainer>
                            <S.ModalHeader>
                                {activeHeader}
                            </S.ModalHeader>
                        </S.RHContainer>

                        <S.ReasonContainer>
                            <S.RBContainer>
                                {activeList}
                            </S.RBContainer>
                        </S.ReasonContainer>
                    </S.ContainerR>
                </S.ContainerB>
            </S.ReturnModal>
        );
    }
}