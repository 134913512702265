import styled from 'styled-components';

export const ProcessConfirmationContainer = styled.div`
    height: 300px;
    width: 375px;
    background: ${props => props.theme.colors.containerBackground};
    margin: auto;
    margin-top: 30px;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        background: ${props => props.theme.colors.white};
        width: auto;
        border: none;
    }
`;

export const Header = styled.h1`
    font-size: ${props => props.theme.typography.headerFontSize};
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.colors.primaryText};
`;

export const Information = styled.p`
    font-size: ${props => props.theme.typography.infoFontSize};
    text-align: center;
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
    color: ${props => props.theme.colors.informationText};
    line-height: ${props => props.theme.typography.lineHeight};
    a{
        color: ${props => props.theme.colors.informationText};
    }
`;

export const SubmitContainer = styled.div`
    width: 80%;
    height: 34px;
    display: flex;
    margin: 0 auto;
`;