import styled from 'styled-components';

export const DropdownContainer = styled.div`

`;

export const SelectContainer = styled.div`
    height: 21px;
    width: 90px;
`;

export const DropdownButton = styled.button`
    height: 100%;
    width: 100%;
    background: ${props => props.disabled ? props.theme.colors.white : 
        props.theme.colors.white};
    border: none;
    &:focus{
        outline: 0;
    }
    font-weight: 600;
    text-align: left;
    display: flex;
    &:hover{
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
    }
    padding: 1px;
    padding-top: 2px;
    padding-left: 3px;

`;

export const BodyContainer = styled.div`
    width: 200px;
    max-height: 125px;
    overflow-y: auto;
    position: relative;
    border: 1px solid ${props => props.theme.colors.formOutline};
    background: ${props => props.theme.colors.white};
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.scrollBarTrack};
        border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.scrollBarHandle};
        border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
`;

export const OptionContainer = styled.div`
    height: 21px;
    width: 100%;
`;

export const IconContainer = styled.div`
    height: 14.5px;
    margin-top: 1px;
    margin-left: auto;
`;

export const OptionButton = styled.button`
    height: 100%;
    width: 100%;
    background: ${props => props.theme.colors.white};
    border: none;
    font-weight: 600;
    text-align: left;
    display: flex;
    padding: 3px;
    &:hover{
        background: ${props => props.disabled ? props.theme.colors.buttonDisabledConsumer : 
            props.theme.colors.buttonBackgroundHoverSecondary};
        cursor: ${props => props.disabled ? "cursor" : "pointer"};
    }
`;