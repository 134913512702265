import React from 'react';
import { connect } from 'react-redux';

import { MdFileDownload } from 'react-icons/md';

import { ReturnConfirmation } from '../ReturnConfirmation';
import { Style } from '../../molecules/Style';
import { ReturnModal } from '../../molecules/ReturnModal';
import { Button } from '../../atoms/Button';
import { TextArea } from '../../atoms/TextArea';
import { InformationLine } from '../../atoms/InformationLine';
import { Divider } from '../../atoms/Divider';
import { Loader } from '../../atoms/Loader';

import { returnTypes, commonMessages, returnFormFileName } from '../../../config';
import { currencyFormat, formatDate } from '../../../util';

import { saveReturn } from '../../../redux/return/actions';
import { fetchOrderForm } from '../../../redux/order/actions';

import * as S from './styles';

class OrderTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            styleSelected: undefined,
            stylesSubmitted: [],
            additionalComments: '',
            loading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onHandleStyleClick = this.onHandleStyleClick.bind(this);
        this.onHandleModalClose = this.onHandleModalClose.bind(this);
        this.getRefundAmount = this.getRefundAmount.bind(this);
        this.getReturnAmount = this.getReturnAmount.bind(this);
        this.getExchangeAmount = this.getExchangeAmount.bind(this);
        this.onTextAreaChange = this.onTextAreaChange.bind(this);
        this.handleFormDownload = this.handleFormDownload.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchOrderForm({
            orderNumber: this.props.orderData.id,
        }))
    }

    handleSubmit() {
        const { dispatch } = this.props;
        this.setState({ loading: true });
        Promise.all([
            dispatch(saveReturn({
                orderNumber: this.props.orderData.id,
                additionalComments: this.state.additionalComments,
                return_lines: this.state.stylesSubmitted
            })),
        ]).then(() => {
            if (!this.props.errorProp) {
                this.setState({
                    loading: false
                });
            }
        });
    }

    handleFormDownload(orderFormData) {
        var buffer = '';
        var bytes = new Uint8Array(orderFormData.length)
        for (var i = 0; i < orderFormData.length; i++) {
            bytes[i] = orderFormData.charCodeAt(i);
        }
        buffer = bytes.buffer;
        var blob = new Blob([buffer], { type: 'applicaiton/pdf' });
        blob.ContentType = "applicaiton/pdf"
        var pdfLink = document.createElement('a');
        pdfLink.href = URL.createObjectURL(blob);
        pdfLink.download = returnFormFileName;
        pdfLink.click()
        return (
            <ReturnConfirmation
                link={pdfLink}
            />
        );
    }

    onHandleModalClose(returnData) {
        if (returnData) {
            var tempList = this.state.stylesSubmitted;
            tempList.push(returnData);
            this.setState({
                stylesSubmitted: tempList,
                styleSelected: undefined
            });
        }
        else {
            this.setState({
                styleSelected: undefined
            });
        }
    }

    onHandleStyleClick(styleSelected) {
        this.setState({
            styleSelected: styleSelected
        });
    }

    onTextAreaChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked :
            target.type === 'select-multiple' ?
                Array.from(target.selectedOptions, option => option.value)
                : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    getReturnAmount() {
        var returnAmount = 0;
        for (let i = 0; i < this.props.orderData.lines.length; i++) {
            returnAmount += this.props.orderData.lines[i].quantity_returned;
        }
        return returnAmount;
    }

    getRefundAmount() {
        var refundAmount = 0;
        for (let i = 0; i < this.state.stylesSubmitted.length; i++) {
            if (this.state.stylesSubmitted[i].returnType === returnTypes.refund.label) {
                refundAmount += this.state.stylesSubmitted[i].amount;
            }
        }
        return refundAmount;
    }

    getExchangeAmount() {
        var exchangeAmount = 0;
        for (let i = 0; i < this.state.stylesSubmitted.length; i++) {
            if (this.state.stylesSubmitted[i].returnType === returnTypes.exchange.label) {
                exchangeAmount++;
            }
        }
        return exchangeAmount;
    }

    render() {
        this.getReturnAmount()
        const isSubmitted = this.props.orderReturnSave.response ?
            true : false;

        var buffer = '';
        if (isSubmitted) {
            var bytes = new Uint8Array(this.props.orderReturnSave.response.length)
            for (var i = 0; i < this.props.orderReturnSave.response.length; i++) {
                bytes[i] = this.props.orderReturnSave.response.charCodeAt(i);
            }
            buffer = bytes.buffer;
            var blob = new Blob([buffer], { type: 'applicaiton/pdf' });
            blob.ContentType = "applicaiton/pdf"
            var pdfLink = document.createElement('a');
            pdfLink.href = URL.createObjectURL(blob);
            pdfLink.download = returnFormFileName;
            return (
                <ReturnConfirmation
                    link={pdfLink}
                />
            );
        }

        const loading = this.state.loading;
        const styleSelected = this.state.styleSelected;
        const orderData = this.props.orderData;
        const styleList = [];

        for (let i = 0; i < orderData.lines.length; i++) {
            styleList.push(
                <Style
                    key={i}
                    submittedData={this.state.stylesSubmitted}
                    styleData={orderData.lines[i]}
                    onHandleClick={this.onHandleStyleClick}
                />
            );
        }
        if (styleSelected) {
            return (
                <ReturnModal
                    styleData={this.state.styleSelected}
                    onClose={this.onHandleModalClose}
                />
            );
        }
        return (
            <S.OrderTableContainer>
                <S.Header>{commonMessages.orderTable.header}</S.Header>

                <S.IHContainer>
                    <S.IHLContainer>
                        <S.IHOrderNumber>
                            <S.InformationHeader>
                                {commonMessages.orderTable.orderNumber}{orderData.id}
                            </S.InformationHeader>
                        </S.IHOrderNumber>

                        <S.IHDate>
                            <S.IHDateP>
                                <S.InformationHeader>
                                    {commonMessages.orderTable.datePurchased}
                            :&nbsp;
                            {formatDate(orderData.date)}
                                </S.InformationHeader>
                            </S.IHDateP>
                        </S.IHDate>
                    </S.IHLContainer>
                    <S.IHRContainer>
                        {this.props.orderFormData &&
                            <S.DownloadContainer>
                                <S.DownloadButton
                                    disabled={this.getReturnAmount() <= 0}
                                    onClick={() => this.handleFormDownload(this.props.orderFormData)}
                                >
                                    {commonMessages.orderTable.returnForm}
                                    <MdFileDownload />
                                </S.DownloadButton>
                            </S.DownloadContainer>
                        }
                    </S.IHRContainer>
                </S.IHContainer>

                <S.StyleContainer>
                    {styleList}
                </S.StyleContainer>

                <S.FooterContainer>
                    <S.FooterLContainer>
                        <S.InformationFooter>
                            {commonMessages.orderTable.additionalComments}
                        </S.InformationFooter>
                        <TextArea
                            value={this.state.additionalComments}
                            onChange={this.onTextAreaChange}
                            name={"additionalComments"}
                        />
                    </S.FooterLContainer>

                    <S.FooterMContainer>
                        <InformationLine
                            label={commonMessages.orderTable.returning}
                            data={this.state.stylesSubmitted.length.toString()}
                            endText={commonMessages.orderTable.style}
                        />
                        <InformationLine
                            label={commonMessages.orderTable.refundAmount}
                            data={currencyFormat.format(this.getRefundAmount().toString())}
                        />
                        <Divider />
                        <InformationLine
                            label={commonMessages.orderTable.exchanging}
                            data={this.getExchangeAmount().toString()}
                            endText={commonMessages.orderTable.style}
                        />
                    </S.FooterMContainer>

                    <S.FooterRContainer>
                        <S.SubmitContainer>
                            <S.ButtonContainer>
                                <Button
                                    disabled={loading || this.state.stylesSubmitted.length <= 0}
                                    label={commonMessages.orderTable.submit}
                                    handleSubmit={this.handleSubmit}
                                />
                            </S.ButtonContainer>
                            {
                                loading &&
                                <S.LoaderContainer>
                                    <Loader />
                                </S.LoaderContainer>
                            }
                        </S.SubmitContainer>
                    </S.FooterRContainer>
                </S.FooterContainer>

            </S.OrderTableContainer>
        );
    }
}

function mapStateToProps(state) {
    const { order } = state;
    const { orderReturnSave } = state;
    const { orderForm } = state;
    var orderData = null;
    var orderFormData = null;
    if (order) {
        const { response } = order;
        if (response) {
            orderData = response.order;
        }
        var errorProp = order.error;
    }
    if (orderForm) {
        const { response } = orderForm;
        if (response) {
            orderFormData = response;
        }
    }
    return {
        orderData,
        orderReturnSave,
        errorProp,
        orderFormData
    };
}

const orderTableContainer = connect(mapStateToProps)(OrderTable);
export { orderTableContainer as OrderTable };