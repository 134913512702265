import { combineReducers } from 'redux';

import { order, 
          orderForm 
       } from './order/reducers';
import { orderReturnSave,
         orderReturnFetch,
         orderReturnsFetch,
         orderReturnProcess,
        } from './return/reducers';

const rootReducer = combineReducers ({
    order,
    orderForm,
    orderReturnSave,
    orderReturnFetch,
    orderReturnsFetch,
    orderReturnProcess
});

export default rootReducer;