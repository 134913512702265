import styled from 'styled-components';

export const UnprocessedReturnsContainer = styled.div`
    height: 510px;
    width: 375px;
    background: ${props => props.theme.colors.containerBackground};
    margin: auto;
    margin-top: 30px;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        background: ${props => props.theme.colors.white};
        width: auto;
        border: none;
    }
`;

export const Header = styled.h1`
    font-size: ${props => props.theme.typography.headerFontSize};
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.colors.primaryText};
    margin-top: 33px;
`;

export const Body = styled.div`
    width: 100.1%;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.scrollBarTrack};
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.scrollBarHandle};
    }
`;

export const URContainer = styled.div`
`;