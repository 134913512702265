export const returnReasons = {
    fit: 
    {
        id: 1,
        label: "Fit",
        data:
        [
            "Cup too small",
            "Cup too big",
            "Band too small",
            "Band too big"
        ]
    },
    service: 
    {
        id: 2,
        label: "Service",
        data:
        [
            "Not as expected",
            "Arrived too late",
            "Wrong item arrived"
        ]
    },
    quality: 
    {
        id: 3,
        label: "Quality",
        data:
        [
            "Damaged/Defective item",
            "Damaged in transit"
        ]
    },
    other: 
    {
        id: 4,
        label: "Other",
        data:
        [
            "Color not as anticipated"
        ]
    }
}

export const rejectReasons = {
    damaged: 
    {
        id: 1,
        label: "Damaged in Transit ",
        shortCode: "Damaged"
    },
    worn: {
        id: 2,
        label: "Worn or used Item",
        shortCode: "Worn"
    },
    stained: {
        id: 3,
        label: "Stained",
        shortCode: "Stained"
    },
    invalidReturn: {
        id: 4,
        label: "Item not valid for return",
        shortCode: "Invalid R."
    },
    invalidExchange: {
        id: 5,
        label:"Invalid exchange reason",
        shortCode: "Invalid E."
    }
}

export const returnTypes = {
    refund: {
        id: 1,
        label: "Refund"
    },
    exchange: {
        id: 2,
        label: "Exchange"
    }
}


export const commonMessages = {
    orderForm: {
        header: "Returns & Exchanges",
        information: "You have 30 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it.",
        privacyPolicy: "By continuing, you agree to our ",
        privacyPolicyLabel: "Privacy Policy",
        returnPolicyLabel: "Complete Return Policy",
        buttonStart: "Start return",
        email: "Email",
        orderNumber: "Order number",
        zip: "Zip / Postal code",
    },
    processForm: {
        header: "Return & Exchange Process",
        information: "Scan barcode or enter code below",
        submit: "Submit",
        code: "RA Number",
        error: "This order does not exist"
    },
    unprocessedReturns: {
        header: "Unprocessed Returns",
        orderNumber: "Order #",
        returnType: "Return Type"
    },
    processTable: {
        process: "Process",
        consumerInformation: "Consumer Information",
        orderNumber: "Order #",
        additionalComments: "Additional Comments",
        name: "Name",
        email: "Email",
        phone: "Phone",
        address: "Address",
        returns: "Returns",
        exchange: "Exchange",
        exchanges: "Exchanges",
        subheaders: {
            style: "Style",
            color: "Color",
            size: "Size",
            quantity: "Qty." ,
            availableQuantity: "Available Qty.",
            acceptQuantity: "Accept Qty.",
            rejectQuantity: "Reject Qty.",
            rejectReason: "Reject reason",
            restockQuantity: "Restock Qty."
        },
        exchangeSku: "Exchange SKU",
        exchangeQuantity: "Exchange Qty.",
        returnReason: "Return reason",
        fulfillmentStatus: "Days since order fulfillment"
    },
    orderTable: {
        header: "Select item(s) to return",
        orderNumber: "Order #",
        returning: "Returning",
        style: "style(s)",
        refundAmount: "Refund amount",
        exchanging: "Exchanging",
        submit: "Submit",
        additionalComments: "Additional comments",
        modalReasonHeader: "We're sorry it didn't work out. Why are you returning it ?",
        modalReturnTypeHeader: "What do you want to do?",
        modalReturning: "Returning",
        modalExchanging: "Exchanging",
        modalQuantity: "Qty.",
        modalMaxQuantity: "Max quantity",
        datePurchased: "Date purchased",
        returnForm: "Return form"
    },
    style: {
        name: "Name",
        color: "Color",
        size: "Size",
        price: "Price",
        quantity: "Order qty.",
        availableQuantity: "Available qty.",
        returnQuantity: "Return qty.",
        type: "Type",
        reason: "Reason"
    },
    returnConfirmation: {
        header: "Your return has been processed",
        information: "Please download the return form PDF and follow the instructions listed",
        returnFormLabel: "Return form"
    },
    processConfirmation: {
        header: "Processed successfully",
        returnToFormLabel: "Return to Process Form"
    },
    dropdown: {
        label: "Select"
    }
}

export const theme = {
    body: {
        backgroundImage: "https://ecommercereturns.s3.amazonaws.com/backgrounds/background.jpg",
    },
    colors: {
        styleContainerSubmitted: "#FAEDF5",
        styleContainerSubmittedBorder: "#431933",
        activeTileBorder: "#431933",
        activeTileBackground: "#FAEDF5",

        buttonBackgroundPrimaryConsumer: "#431933",
        buttonBackgroundHoverPrimaryConsumer: "#69214A",
        buttonDisabledConsumer: "#7D375F",
        buttonBackgroundSecondary: "#FFFFFF",

        buttonBorderSecondary: "#999999",
        buttonTextSecondary: "#000000",
        buttonBackgroundHoverSecondary: "#F4F4F4",
        formDisabled: "#F2FAFF",

        buttonBackgroundPrimaryNonConsumer: "#4285F4",
        buttonBackgroundHoverPrimaryNonConsumer: "#57A2F4",
        buttonDisabledNonConsumer: "#8CB7FF",
        
        loaderBorder: "#C1C1C1",
        loaderSpinner: "#FFFFFF",
        loaderFullBorder: "#4285F4",
        styleButtonDisabled: "#E3E3E3",
        lineContainerDisabled: "#E3E3E3",
        tileButtonDisabled: "#E3E3E3",
        textDisabled: "#AEB4B7",

        containerBackground: "#FFFFFF",
        styleContainerHover: "#F4F4F4",
        iconHover: "#666666",
        iconDisabled: "#D3D3D3",
        tileHover: "#F4F4F4",
        styleContainerHoverBorder: "#777777",
        formOutline: "#D3D3D3",
        border: "#D3D3D3",
        black: "#000000",
        white: "#FFFFFF",
        informationText: "#707070",
        informationData: "#777777",
        placeholderText: "#999999",
        primaryText: "#707070",
        scrollBarTrack: "#F1F1F1",
        scrollBarHandle: "#C1C1C1",
        divider: "#D3D3D3",
        styleBorderInactive: "#D3D3D3",
        buttonText: "#FFFFFF",
        errorText: "#FF5000",

        positiveBackground: "#DCFFCF",
        positiveBorder: "#74D657",
        negativeBackground: "#FFD1D1",
        negativeBorder: "#E27474"

    },
    components: {
        borderRadiusConsumer: "5px",
        borderRadiusNonConsumer: "0px"
    },
    screen: {
        breakpoint: "1024px"
    },
    typography: {
        headerFontSize: "1.25rem",
        infoHeaderFontSize: "0.9rem",
        infoFooterFontSize: "0.9rem",
        infoFontSize: "0.775rem",
        lineHeight: "1.5rem",
    }
}

export const commonTypes = {
    return: {
        typeName: "return",
        backendReturnType: "refund",
        header: "Returns"
    },
    exchange: {
        typeName: "exchange",
        backendReturnType: "exchange",
        header: "Exchanges"
    }
}

export const returnCodePrefix = "DA111|"
export const backendBase = "https://dayleen.frogfishsolutions.io";
export const backendWebhook = backendBase + "/plugins/mirumee.returns/webhook";

export const privacyPolicyUrl = "https://dominiqueapparel.com/page/privacy-policy/";
export const returnPolicyUrl = "https://dominiqueapparel.com/page/return-policy/";
export const emailMailToSubject = "Dominique Returns";
export const returnFormFileName = "return-form.pdf";
export const processCredentialInputId =  "returnsProcessKeyFF";
export const erpUrl = "https://localhost:444";